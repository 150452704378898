import React, { useEffect, useRef } from 'react';
import logo from './img/EU logo.png';
import carousel from './img/campus.webp';
import { CCarousel, CCarouselItem } from '@coreui/react';
import 'animate.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Recongination } from './pages/Recongination';
import { Course } from './pages/Course';
import { Footer } from './pages/Footer';
import { Faq } from './pages/Faq';
import { Aboutus } from './pages/Aboutus';
import { Background } from './pages/welcomepage';
import { Contact } from './pages/Contact';

export const Home = () => {

    const textRef = useRef(null);


    useEffect(() => {
        const handleScroll = () => {
            if (textRef.current) {  // Ensure the element is not null
                const textPosition = textRef.current.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3;

                if (textPosition < screenPosition) {
                    textRef.current.classList.add('animate');
                } else {
                    textRef.current.classList.remove('animate');
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);






    return (

        <div className='background-color'>
            <div className="container-fluid topbar px-0 px-lg-4 bg-light py-2 d-none d-lg-block">
                <div className="container">
                    <div className="row gx-0 align-items-center">
                        <div className="col-lg-8 text-center text-lg-start mb-lg-0">
                            <div className="d-flex flex-wrap">
                                <div className="border-end border-primary pe-3">
                                    <a href="/" className="text-muted small">
                                        <i className="fas fa-map-marker-alt text-primary me-2"></i>
                                        Find A Location
                                    </a>
                                </div>
                                <div className="ps-3">
                                    <a href="mailto:example@gmail.com" className="text-muted small">
                                        <i className="fas fa-envelope text-primary me-2"></i>
                                        info@eugmc.org
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center text-lg-end">
                            <div className="d-flex justify-content-end">
                                <div className="d-flex border-end border-primary pe-3">
                                    <a className="btn p-0 text-primary me-3" rel="noreferrer" href="https://www.facebook.com/royalnextacademy13" target="_blank">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="btn p-0 text-primary me-3" rel="noreferrer" href="https://twitter.com/Royalnextoff" target="_blank">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="btn p-0 text-primary me-3" rel="noreferrer" href="https://www.instagram.com/royalnextacademy/" target="_blank">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a className="btn p-0 text-primary me-0" rel="noreferrer" href="https://www.youtube.com/channel/UCXEIdPJUowws2aQ58rFv72A" target="_blank">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="container-fluid nav-bar px-0 px-lg-4 py-lg-0">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <a href="/" className="navbar-brand">
                            <img src={logo} alt="Logo" />
                        </a>
                        <Navbar bg="light" expand="sm" className="navbar-custom">
                            <Container className='navbar-container'>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                    <Nav className="navbar-link">
                                        <Nav.Link href="#home" className="nav-link">Home</Nav.Link>
                                        <Nav.Link href="#about" className="nav-link">About</Nav.Link>
                                        <Nav.Link href="#contact" className="nav-link">Contact</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <div className="d-none d-xl-flex flex-shrink-0 ps-4">
                            <a
                                href="/"
                                className="btn btn-light btn-lg-square rounded-circle position-relative wow tada"
                                data-wow-delay=".9s"
                            >
                                <i className="fa fa-phone-alt fa-2x" style={{ top: '9px' }}></i>
                                <div
                                    className="position-absolute"
                                    style={{ top: '9px', right: '12px' }}
                                >
                                </div>
                            </a>
                            <div className="d-flex flex-column ms-3">
                                <span className="text-dark">Call to Our Experts</span>
                                <a href="tel:+01234567890">
                                    <span className="text-dark">Free: +91 9962705000</span>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>


            <div>
            <CCarousel controls indicators>
                <CCarouselItem className='carousel-bg'>

                    <div className="container">
                        <div className="row g-4 align-items-center">
                            <div className="col-lg-7 animated fadeInLeft">
                                <div className="text-sm-center text-md-start">
                                    <h4 className="text-white text-uppercase fw-bold mb-4">Best Georgia MBBS University</h4>
                                    <h3 className="display-1 text-white mb-4">WELCOME TO EUROPEAN UNIVERSITY</h3>
                                    <p className="mb-5 fs-5 text-white "> The European University Tbilisi shines as one of the fastest-growing medical institutes worldwide...</p>
                                    <div className="d-flex justify-content-center justify-content-md-start flex-shrink-0 mb-4">
                                        <a className="btn btn-light rounded-pill py-3 px-4 px-md-5 me-2" rel="noreferrer" href="https://www.youtube.com/channel/UCXEIdPJUowws2aQ58rFv72A" target="_blank">
                                            <i className="fas fa-play-circle me-2"></i> Watch Video
                                        </a>
                                        <a className="btn btn-dark rounded-pill py-3 px-4 px-md-5 ms-2" href="/">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 animated fadeInRight">
                                <div className="calrousel-img" style={{ objectFit: 'cover' }}>
                                    <img src={carousel} className="img-fluid w-100" alt="png logo" />
                                </div>
                            </div>
                        </div>
                    </div>

                </CCarouselItem>

                <CCarouselItem className='carousel-bg' >

                    <div className="container">
                        <div className="row gy-4 gy-lg-0 gx-0 gx-lg-5 align-items-center">
                            <div className="col-lg-5 animated fadeInLeft">
                                <div className="calrousel-img">
                                    <img src={carousel} className="img-fluid w-100" alt="png logo" />
                                </div>
                            </div>
                            <div className="col-lg-7 animated fadeInRight">
                                <div className="text-sm-center text-md-end">
                                    <h4 className="text-white text-uppercase fw-bold mb-4">Best Georgia MBBS University</h4>
                                    <h3 className="display-1 text-white mb-4">WELCOME TO EUROPEAN UNIVERSITY</h3>
                                    <p className="mb-5 text-white fs-5">The European University Tbilisi shines as one of the fastest-growing medical institutes worldwide...</p>
                                    <div className="d-flex justify-content-center justify-content-md-end flex-shrink-0 mb-4">
                                        <a className="btn btn-light rounded-pill py-3 px-4 px-md-5 me-2" rel="noreferrer" href="https://www.youtube.com/channel/UCXEIdPJUowws2aQ58rFv72A" target="_blank">
                                            <i className="fas fa-play-circle me-2"></i> Watch Video
                                        </a>
                                        <a className="btn btn-dark rounded-pill py-3 px-4 px-md-5 ms-2" href="/">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCarouselItem>
            </CCarousel>
            </div>

            <div>
                <Background />
            </div>
            <div>
                <Course />
            </div>
            <div className="container-fluid feature bg-light py-5">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-3">
                                    <i className="fas fa-book-open fa-3x"></i>
                                </div>
                                <h4 className="mb-2  ">High quality education</h4>
                                <p className="mb-4 feature-p">
                                    The European University provides top-notch education to medical students from all over the world at an affordable cost. </p>
                                
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-3">
                                    <i className="fa fa-donate fa-3x"></i>
                                </div>
                                <h4 className="mb-2 feature-h4">No Donation Policy</h4>
                                <p className="mb-4 feature-p">
                                    The European University follows a strict no donation policy that gives all the students an equal chance to grow as a doctor without draining their bank accounts.
                                </p>
                                
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-3">
                                    <i className="fa fa-dollar-sign fa-3x"></i>
                                </div>
                                <h4 className="mb-2">Affordable fee structure
                                </h4>
                                <p className="mb-4 feature-p">
                                    If you compare the fee structure of European University with any other medical institute, you will come to know how much affordable ..</p>
                                
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="feature-item p-4 pt-0">
                                <div className="feature-icon p-4 mb-3">
                                    <i className="fa fa-graduation-cap fa-3x"></i>
                                </div>
                                <h4 className="mb-2">Internship Opportunity</h4>
                                <p className="mb-4 feature-p">The European University curriculum provides medical students with a mandatory 1 year of internship to polish their skills..
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Faq />
            </div>
            <div>
                <Aboutus />
            </div>
            <div>
                <Recongination />
            </div>
            <div>
                <Contact />
            </div>
            
            <div>
                <Footer />
            </div>
            

        </div>

    )
}
