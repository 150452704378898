import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../css/contact.css'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export const Contact = () => {
  return (
    <div>
    <Container fluid className="py-5 contact-us bg-dark">
    <Row className="justify-content-center align-items-stretch">
        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mb-4">
          <div className="form-container">
            <h2 className='text-white text-center'>Contact Us</h2>
          <Form className='p-5 bg-white rounded m-2 mt-4'>
            <div className="contact-details-container">
              <div className="contact-info">
                <p className='text-dark'><strong>Address:</strong>32/67, East Jones Road, Saidapet. Chennai-600015, Tamil Nadu.</p>
                <p className='text-dark'><strong>Phone:</strong> +91 9962705000 <br />+91 9940830780 <br />+91 9962705002 <br />+91 9962705004 <br />+91 9962705006 <br />+91 9940111180 <br />+971 545255909</p>
                <p className='text-dark'><strong>Email:</strong> info@eugmc.org</p>
                <p className='text-dark'><strong>International office:</strong> International office.
                  No 810, B Block, Hili tower, Al nadha, Sharjah, UAE.
                  <br />
                  +971 545255909</p>
              </div>
            </div>
          </Form>
          </div>
        </Col>

        <Col xs={10} md={6}>
        <div className="map-container">
            <MapContainer
              center={[41.772327, 44.793039]} 
              zoom={18}
              style={{ width: "100%", height: "80%", padding: '5px', top:'50px' }} 
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://maps.app.goo.gl/VJDbAvLp94VxcJzu9">OpenStreetMap</a> contributors'
              />
              <Marker position={[41.772327, 44.793039]}>
                <Popup>European University</Popup>
              </Marker>
            </MapContainer>
            </div>
        </Col>
      </Row>
    </Container>
    </div>
  )
}
