import React from 'react'
import '../css/background.css';

export const Background = () => {
  return (
    <div>
        <div className="home-container">
  <div className="homecontent-container">
    <div className="home-background-image"></div>
    <div className="text-overlay">
                        <h1 className="display-4 mb-2 text-white text-header">European University Georgia</h1>
                        <p className="mb-0 feature-bold">
                        The European University Georgia attracts a large number of medical aspirants from all over the world. The Georgia MBBS university stands as the first choice of many students due to the high-quality education that offered the best placements and all-round development to students at a reasonable cost. The European University Tbilisi shines as one of the fastest-growing medical institutes worldwide.  
                        </p>
                        <p className="second-paragraph"> The European Medical University leading Georgia MBBS university is situated in Tbilisi, the capital city of Georgia. With an excellent academic reputation and strong traditions, the college offers international students access to premium education at a low cost. The University is established for providing international education, research and consultancy in high-quality programs to healthcare professionals and decision-makers. The integrated curriculum is built on the best science and profession-specific teaching methods of world-class institutions to ensure you receive the best education possible. The European University is often regarded as the best medical college in Georgia.</p>
    </div>
  </div>
</div>

    </div>
  )
}
