import React from 'react'
import aboutImage from '../img/aboutus.webp';
import '../css/style.css';
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

export const Aboutus = () => {

   // Initialize Intersection Observer
  const { ref: counterRef, inView: isCounterVisible } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.5,    // 50% of the element is in view
  });

  return (
    <div>
          <div className="container-fluid  about pb-5" >
      <div className="container pb-5">
        <div className="row g-5">
         
          <div
            className="col-xl-6 wow fadeInRight"
            data-wow-delay="0.2s" 
          >
            <div className=" rounded p-5 h-100 w-150 mt-5"
            ref={counterRef} >
              <div className="row g-4 justify-content-center">
                <div className="col-12">
                  <div className="rounded bg-light">
                    <img
                      src={aboutImage}
                      className="img-fluid rounded w-100"
                      alt="About Us"
                      loading="lazy" 
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item  bg-primary rounded p-3 h-100">
                    <div className="counter-counting">
                    {isCounterVisible && (
                        <span className="text-white p-2  fs-2 fw-bold">
                          <CountUp end={2200} duration={2} />
                        </span>
                      )}
                      <span className="h1 fw-bold text-white">+</span>
                    </div>
                    <h4 className="mb-0 text-white"> students</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                    {isCounterVisible && (
                        <span className="text-primary fs-2 fw-bold">
                          <CountUp end={10} duration={2} />
                        </span>
                      )}
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-primary">years of success </h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                    {isCounterVisible && (
                        <span className="text-primary fs-2 fw-bold">
                          <CountUp end={2000} duration={2} />
                        </span>
                      )}
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-primary">Graduates </h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-primary rounded p-3 h-100">
                    <div className="counter-counting">
                    {isCounterVisible && (
                        <span className="text-white fs-2 fw-bold">
                          <CountUp end={500} duration={2} />
                        </span>
                      )}
                      <span className="h1 fw-bold text-white">+</span>
                    </div>
                    <h4 className="mb-0 text-white">Full time faculty</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 wow fadeInLeft"
            data-wow-delay="0.2s"
          >
            <div className="about-item-content bg-white rounded p-5 h-85 inner-shadow">
              <h4 className="text-dark mt-4">About</h4>
              <h1 className="display-4 text-secondry"> European University</h1>
              <p>
              Indian students prefer the European Medical University as their top MBBS college
                in Georgia.
              Georgian European University is an example of educational excellence in the medical 
                field. The college offers advanced education that helps MBBS aspirants succeed as doctors, which
                 makes it the best medical college in Georgia.  Medical students from throughout the world can 
                 receive a top-notch education at an affordable price from Georgian European University.
                
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
