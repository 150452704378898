import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './lib/animate/animate.min.css';
import './lib/lightbox/css/lightbox.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import { Home } from './Home';
import { Popup } from './Popup';




const loadFonts = () => {
  const link1 = document.createElement('link');
  link1.rel = 'preconnect';
  link1.href = 'https://fonts.googleapis.com';

  const link2 = document.createElement('link');
  link2.rel = 'preconnect';
  link2.href = 'https://fonts.gstatic.com';
  link2.crossOrigin = 'anonymous';

  const link3 = document.createElement('link');
  link3.rel = 'stylesheet';
  link3.href = 'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:slnt,wght@-10..0,100..900&display=swap';

  document.head.appendChild(link1);
  document.head.appendChild(link2);
  document.head.appendChild(link3);
};

loadFonts();






function App() {
  return (
    <div className="App">
      <Popup />
      <Home />
    </div>
  );
}

export default App;
