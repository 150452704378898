import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './popup.css';
import axios from 'axios';

export const Popup = () => {

    const [show, setShow] = useState();
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [neet, setNeet] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');


    const handleClose = () => setShow(false);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setShow(true);
      }, 10000); 
      return () => clearTimeout(timer); 
    }, []);
  

    const sendEmail = async () => {
      try {
        await axios.post('http://localhost:5000/send-email', { name,mobile,email,neet,message });
        setStatus('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
        setStatus('Error sending email');
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
     
      console.log('Form data submitted:');
      handleClose();
    };
  
  return (
    <div>
           <Modal className='popup' show={show} onHide={handleClose} centered>
           <Modal.Header closeButton>
           </Modal.Header>
           <Modal.Body>
          <p>Got questions or doubts? Our expert counselors are here to clear them up! Honest advice, guaranteed admissions, and no need for donations—
          Just good vibes and solid answers. 
            </p><p><a><i class="fa fa-phone" id="i_fd84_0"></i>+91 9962705000</a></p>
          <Form className='form-container' onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder='Name'
                required
              />
            </Form.Group>
            <Form.Group controlId="formmobile">
              <Form.Control
                type="text"
                name="mobile"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                placeholder='mobile No'
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder='Email'
                required
              />
            </Form.Group>
            <Form.Group controlId="formaddress">
              <Form.Control
                type="text"
                name="address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                placeholder='address'
                required
              />
            </Form.Group>
            <Form.Group controlId="formneet">
              <Form.Control
                type="text"
                name="neet"
                value={neet}
                onChange={(e) => {
                  setNeet(e.target.value);
                }}
                placeholder='NEET Score'
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Control
                as="textarea"
                name="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder='Message '
                rows={3}
                required
              />
            </Form.Group>
            <Button variant="primary" onClick={sendEmail} type="submit" className="mt-3">
              Submit
            </Button>
            <p>{status}</p>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
